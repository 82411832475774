import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import formatPhone from "../helpers/formatPhone";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import ThemeContext from '../context/ThemeContext';
import LocationCard from "../components/locationCard";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./appointments.module.scss";
// SVG 
import locationIcon from '../images/svg-icons/location-icon-green.svg'

export default (props) => {

	const {
		data: {
			appointmentsPage,
			appointmentsPage: {
				title,
				ACFAppointments: {
					appointmentsInfo: {
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							}
						},
						appointmentsContent
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			locations: {
				nodes: locations
			},
			plaqusterDougie,
			plaqusterRojo,
			rightArrowYellowOffsite,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			rightArrowGray,
			arrowRedLocal,
			// locationIcon,
		}
	} = props;

	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	const bookingCardsRef = useRef(null);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
	
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}
		// Scroll to the location cards on page load 
		const scrollToBookingCards = (ref) => window.scrollTo({top: ref.current.offsetTop - 100, behavior: 'smooth'});
		const executeScrollToBookingCards = () => scrollToBookingCards(bookingCardsRef)


		getLocation();
		sortByDistance();
		executeScrollToBookingCards();

	}, [location, locations, getLocation])

	function directionLink() {
		if (!nearestLoc.birdeyeLocation.location.lat || !nearestLoc.birdeyeLocation.location.lng) {
			return;
		}

		let placeIdParam = '';

		if (nearestLoc.ACFLocation.googlePlaceId) {
			placeIdParam = `&destination_place_id=${nearestLoc.ACFLocation.googlePlaceId}`;
		}

		return <a className={styles.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${nearestLoc.birdeyeLocation.location.lat}, ${nearestLoc.birdeyeLocation.location.lng}${placeIdParam}`} target="_blank" rel="noopener noreferrer">Get Directions <FixedImage image={rightArrowYellowOffsite} passedClass={styles.icon} /></a>;
	}

	return (
		<Layout hideCtaButton={true} hideMobileCtaButton={true}>

			<Seo post={appointmentsPage} />
			<div className={`${styles.top} hero`}>
				{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
				{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
				{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
				{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
				<div className={styles.dougie}>
					<FluidImage image={plaqusterDougie} loading={"eager"} fadeIn={false} alternativeText={'Dougie, the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.rojo}>
					<FluidImage image={plaqusterRojo} loading={"eager"} fadeIn={false} alternativeText={'Rojo the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.left}>
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
					<h1 className={styles.servicename} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h1>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introBlurb}}data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>
				<div className={styles.right}>
					<div className={styles.lefttop}>
						<div className={styles.NearestLocation}>
							<div className={styles.topbar}>
								<span className={styles.myloc}><img src={locationIcon} className={styles.icon} alt="My Location" /> My location</span>
							</div>
							{ nearestLoc === null && (
								<div className={styles.top}>
									<span className={styles.title}>Loading...</span>
								</div>
							)}
							{
								nearestLoc !== null && (
									<>
										<div className={styles.top}>
											<Link to={nearestLoc.link} className={styles.title} dangerouslySetInnerHTML={{__html: nearestLoc.title}}></Link>
										</div>
										<div className={styles.bottom}>
											{ nearestLoc.birdeyeLocation.location.address1 && <span className={styles.address} dangerouslySetInnerHTML={{__html: nearestLoc.birdeyeLocation.location.address1 }}></span>}
											{ nearestLoc.birdeyeLocation.location.address2 && <span className={styles.address} dangerouslySetInnerHTML={{__html: nearestLoc.birdeyeLocation.location.address2 }}></span>}
											{ nearestLoc.birdeyeLocation.location.zip && nearestLoc.birdeyeLocation.location.state && nearestLoc.birdeyeLocation.location.city && <span className={styles.address} dangerouslySetInnerHTML={{__html: `${nearestLoc.birdeyeLocation.location.city}, ${nearestLoc.birdeyeLocation.location.state} ${nearestLoc.birdeyeLocation.location.zip}`}}></span>}
											{ directionLink() }
											{ nearestLoc.birdeyeLocation.phone && <a className={styles.phone} href={`tel:${nearestLoc.birdeyeLocation.phone}`}>{formatPhone(nearestLoc.birdeyeLocation.phone)}</a>}
											{ nearestLoc.ACFLocation.bookMyAppointmentLink && <WPLink link={nearestLoc.ACFLocation.bookMyAppointmentLink} passedClass={styles.link} />}
											{ !nearestLoc.ACFLocation.bookMyAppointmentLink && nearestLoc.birdeyeLocation.phone && <a className={styles.link} href={`tel:${nearestLoc.birdeyeLocation.phone}`}>Call Now</a>}
										</div>
									</>
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				{ appointmentsContent && <div ref={bookingCardsRef}
					className="wpcontent" 
					dangerouslySetInnerHTML={{__html: appointmentsContent}} 
					data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
				<div className={styles.locationcontainer}>
					{ locations && locations.map((location, i) => {   
						return (
							<div key={location.id} className={`${styles.location} ${styles.apptpage}`} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								<LocationCard 
									key={location.id} 
									location={location} 
									arrowRedLocal={arrowRedLocal} 
									rightArrowYellowOffsite={rightArrowYellowOffsite} 
									isTeamPage={false} 
									isAppointmentsPage={true} 
									rightArrowGray={rightArrowGray} 
								/>
							</div>
						);
					})} 
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		appointmentsPage: wpPage(id: { eq: $id }) {
			id
			title
			ACFAppointments {
				appointmentsInfo {
					right {
						introContent {
							blurb
							heading
						}
		
					}
					appointmentsContent
				}
			}
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		appointmentPage: allWpPage(filter: {template: {templateName: {eq: "Appointments Page"}}}) {
			nodes {
			  link
			}
		}
		plaqusterDougie: file(name: {eq: "dougie"}) {
			childImageSharp {
				fluid(maxWidth: 140){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		plaqusterRojo: file(name: {eq: "rojo"}) {
			childImageSharp {
				fluid(maxWidth: 160){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		rightArrowGray: file(name: {eq: "arrow_gray_local"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		locations: allWpLocation(sort: {fields: title}) {
			nodes {
			  id
			  link
			  title
			  birdeyeLocation {
					businessId
					timezone
					hoursOfOperations {
						day
						isOpen
						workingHours {
							startHour
							endHour
						}
					}
					location {
						lat
						lng
						address1
						address2
						city
						state
						zip
					}
					name
					phone
				}
			  ACFLocation {
				googlePlaceId
				bookMyAppointmentLink {
				  target
				  title
				  url
				}
			  }
			}
		}
	}
`;
